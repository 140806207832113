var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"commonDialog":_vm.visitScheduleDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Schedule a visit "),_c('v-icon',{staticClass:"mx-4 color-custom-blue",attrs:{"small":""}},[_vm._v("mdi-checkbox-blank-circle")]),_vm._v(" Job# "+_vm._s(_vm.detail.barcode))]},proxy:true},{key:"body",fn:function(){return [_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"60vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-form',{ref:"visitForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateOrCreate.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('v-text-field',{staticClass:"remove-border-radius custom-grey-border",attrs:{"dense":"","filled":"","flat":"","rules":[
                  () => !!_vm.visitData.title || 'Title field is required',
                ],"disabled":_vm.pageLoading,"label":"Title","solo":"","color":"cyan","hide-details":""},model:{value:(_vm.visitData.title),callback:function ($$v) {_vm.$set(_vm.visitData, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"visitData.title"}}),_c('v-textarea',{staticClass:"remove-border-radius custom-grey-border",attrs:{"auto-grow":"","dense":"","filled":"","flat":"","disabled":_vm.pageLoading,"label":"Instructions","solo":"","color":"cyan","hide-details":"","row-height":"30"},model:{value:(_vm.visitData.instruction),callback:function ($$v) {_vm.$set(_vm.visitData, "instruction", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"visitData.instruction"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('table',{staticClass:"width-100 font-weight-500"},[_c('tr',[_c('td',{staticClass:"color-custom-blue text-uppercase font-size-16 ml-4 py-2 font-weight-600"},[_vm._v(" Job details ")])]),_c('tr',{staticClass:"custom-border-bottom"},[_c('td',{staticClass:"py-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.lodash.isEmpty(_vm.customer) === false)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-account")])],1),_c('v-list-item-content',{staticClass:"p-0"},[(_vm.pageLoading)?_c('v-skeleton-loader',{staticClass:"custom-skeleton",attrs:{"type":"text"}}):_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"},[_c('router-link',{attrs:{"to":_vm.getDefaultRoute('customer.detail', {
                                    params: {
                                      id: _vm.customer.customer,
                                    },
                                  })}},[_vm._v(_vm._s(_vm.detail.attention))])],1)],1),_c('v-list-item-action',{staticClass:"m-0 ml-4",attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                                _vm.getDefaultRoute('customer.detail', {
                                  params: {
                                    id: _vm.customer.customer,
                                  },
                                })
                              )}}},[_c('v-icon',{staticClass:"color-custom-blue"},[_vm._v("mdi-link mdi-rotate-135")])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Attention")])])],1)]),_c('tr',{staticClass:"custom-border-bottom"},[_c('td',{staticClass:"py-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.contactPerson)?_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1 max-content-width"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-phone")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"},[_c('a',{attrs:{"href":'tel:' + _vm.contactPerson.primary_phone}},[_vm._v(_vm._s(_vm.contactPerson.primary_phone))])])],1)],1):_vm._e()]}}])},[_c('span',[_vm._v("Contact Phone")])])],1)]),_c('tr',[_c('td',{staticClass:"py-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-list-item',_vm._g(_vm._b({staticClass:"py-1"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',{staticClass:"margin-auto-zero mr-4"},[_c('v-icon',{staticClass:"font-size-28"},[_vm._v("mdi-home-map-marker")])],1),_c('v-list-item-content',{staticClass:"p-0"},[_c('v-list-item-title',{staticClass:"custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"},[_c('a',{attrs:{"target":"_blank","href":'https://maps.google.com/?q=' +
                                  _vm.PropertyDetail}},[_vm._v(_vm._s(_vm.PropertyDetail))])])],1)],1)]}}])},[_c('span',[_vm._v("Property Address")])])],1)])])])],1),_c('OneOffScheduleDetail',{attrs:{"pageLoading":_vm.pageLoading,"internal":_vm.isInternal,"dailyRecurrence":_vm.dailyRecurrence,"monthlyRecurrence":_vm.monthlyRecurrence,"weeklyRecurrence":_vm.weeklyRecurrence}})],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading || _vm.pageLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.updateOrCreate}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading || _vm.pageLoading},on:{"click":_vm.closeDialog}},[_vm._v(" Close ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }