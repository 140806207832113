<template>
  <div class="one-off-schedule-body">
    <template v-if="!schedule_later">
      <v-container fluid>
        <v-row>
          <v-col :md="showCalendar ? 12 : 4">
            <label class="font-weight-700 font-size-16">Start Date</label>
            <v-menu
              v-model="startDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Start Date"
                  hide-details
                  solo
                  :rules="[
                    validateRules.required(schedule.start_date, 'Start Date'),
                  ]"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedStartDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="schedule.start_date"
                color="cyan"
                :min="todayDate"
                v-on:input="startDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col v-if="false" :md="showCalendar ? 12 : 4">
            <label class="font-weight-700 font-size-16">End Date</label>
            <v-menu
              v-model="endDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="End Date"
                  hide-details
                  solo
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedEndDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="schedule.end_date"
                color="cyan"
                :min="schedule.start_date"
                v-on:input="endDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col :md="showCalendar ? 6 : 4">
            <label class="font-weight-700 font-size-16">Start Time</label>
            <v-menu
              ref="rawStartTime"
              v-model="startTimePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value="rawStartTime"
              transition="scale-transition"
              offset-y
              color="cyan"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Start Time"
                  hide-details
                  solo
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  v-model.trim="schedule.start_time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="startTimePicker"
                v-model="rawStartTime"
                full-width
                ampm-in-title
                color="cyan"
                v-on:click:minute="updateStartTime(rawStartTime)"
                v-on:update:period="updateStartTimeAMPM"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col :md="showCalendar ? 6 : 4">
            <label class="font-weight-700 font-size-16">End Time</label>
            <v-menu
              ref="rawEndTime"
              v-model="endTimePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value="rawEndTime"
              transition="scale-transition"
              offset-y
              color="cyan"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="End Time"
                  hide-details
                  solo
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  v-model.trim="schedule.end_time"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="endTimePicker"
                v-model="rawEndTime"
                full-width
                ampm-in-title
                color="cyan"
                v-on:click:minute="updateEndTime(rawEndTime)"
                v-on:update:period="updateEndTimeAMPM"
              ></v-time-picker>
            </v-menu>
          </v-col>
          <v-col
            md="12"
            class="pb-0"
            v-if="scheduleString.start_date && !internal"
          >
            <p
              class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
            >
              This job will start on {{ scheduleString.start_date }}
              {{ scheduleString.start_time }} and finish on
              {{ scheduleString.end_date }} {{ scheduleString.end_time }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-layout v-if="false">
      <v-col md="12">
        <v-checkbox
          v-model.trim="schedule_later"
          label="Schedule Later"
          :value="1"
          hide-details
          color="cyan"
          class="mr-4 mt-0"
        ></v-checkbox>
      </v-col>
    </v-layout>
  </div>
</template>

<script>
import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { JobEventBus } from "@/core/lib/job/job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "one-off-schedule-body",
  mixins: [ValidationMixin],
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    dailyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    monthlyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    weeklyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      schedule: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
      schedule_later: 0,
      startDatePicker: false,
      endDatePicker: false,
      rawStartTime: null,
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      scheduleString: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
    };
  },
  watch: {
    schedule: {
      deep: true,
      immediate: true,
      handler(param) {
        this.doActions(param);
      },
    },
    schedule_later(param) {
      JobScheduleTeamEventBus.$emit("update:schedule-later", !!+param);
    },
  },
  methods: {
    updateStartTimeAMPM(param) {
      let startTime = this.lodash.toString(this.schedule.start_time);
      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }
        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM(param) {
      let endTime = this.lodash.toString(this.schedule.end_time);
      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }
        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    doActions(param) {
      this.scheduleString.start_date = null;
      this.scheduleString.end_date = null;
      this.scheduleString.start_time = null;
      this.scheduleString.end_time = null;
      JobEventBus.$emit("update:one-off-schedule", param);
      JobScheduleTeamEventBus.$emit("get:schedule-team", param);
    },
    updateStartTime(rawTime) {
      this.$refs.rawStartTime.save(rawTime);
      this.schedule.start_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
    },
    updateEndTime(rawTime) {
      this.$refs.rawEndTime.save(rawTime);
      this.schedule.end_time = moment(rawTime, ["HH:mm"]).format("h:mm A");
    },
    allowedDates(param) {
      if (param && moment(param).isValid()) {
        if (this.lodash.isEmpty(this.weeklyRecurrence) === false) {
          return this.lodash.includes(
            this.weeklyRecurrence.weeks,
            moment(param).day()
          );
        }
      }
      return true;
    },
  },
  computed: {
    todayDate() {
      return moment().format("YYYY-MM-DD");
    },
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.schedule.start_date).format(Config.dateFormat);
      }
      return this.schedule.start_date;
    },
    formattedEndDate() {
      let Config = AppConfiguration.get();
      if (Config && this.schedule.end_date) {
        return moment(this.schedule.end_date).format(Config.dateFormat);
      }
      return this.schedule.end_date;
    },
  },
  beforeMount() {
    if (!this.schedule.start_date) {
      this.schedule.start_date = new Date().toISOString().substr(0, 10);
    }
  },
  mounted() {
    JobScheduleTeamEventBus.$emit("update:schedule-later", false);
  },
  beforeDestroy() {
    /*JobScheduleTeamEventBus.$off("update:schedule-text");
    JobScheduleTeamEventBus.$off("update:schedule-date");
    JobScheduleTeamEventBus.$off("update:assigned-team");*/
  },
  created() {
    const _this = this;

    if (_this.internal) {
      _this.schedule_later = 0;
    }

    JobScheduleTeamEventBus.$on("update:schedule-date", (scheduleDate) => {
      _this.schedule.start_date = scheduleDate;
    });

    JobScheduleTeamEventBus.$on("update:schedule-text", (argument) => {
      _this.scheduleString.start_date = moment(argument.start_date).format(
        "DD MMMM YYYY"
      );
      _this.scheduleString.end_date = moment(argument.end_date).format(
        "DD MMMM YYYY"
      );
      _this.scheduleString.start_time = moment(argument.start_time, [
        "HH:mm",
      ]).format("hh:mm A");
      _this.scheduleString.end_time = moment(argument.end_time, [
        "HH:mm",
      ]).format("hh:mm A");
    });

    /*JobScheduleTeamEventBus.$on("update:assigned-team", ({ assigned_team }) => {
      _this.schedule_later = 1;
      if (
        _this.lodash.isEmpty(assigned_team) === false &&
        assigned_team.length > 0
      ) {
        _this.schedule_later = 0;
      }
    });*/
  },
};
</script>
