<template>
  <div class="one-off-schedule">
    <v-container fluid class="pt-0">
      <v-row
        dense
        class="schedule-tab-item"
        :class="{ 'd-block': showCalendar }"
      >
        <v-col :md="showCalendar ? 12 : 8">
          <div class="schedule-tab-item-header custom-grey-border p-3">
            <OneOffScheduleHeader
              :showCalendar="showCalendar"
              :internal="internal"
            ></OneOffScheduleHeader>
          </div>
          <div class="schedule-tab-item-body custom-grey-border p-3">
            <OneOffScheduleBody
              :showCalendar="showCalendar"
              :internal="internal"
              :dailyRecurrence="dailyRecurrence"
              :monthlyRecurrence="monthlyRecurrence"
              :weeklyRecurrence="weeklyRecurrence"
            ></OneOffScheduleBody>
          </div>
        </v-col>
        <v-col :md="showCalendar ? 12 : 4">
          <JobScheduleTeam></JobScheduleTeam>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import OneOffScheduleHeader from "@/view/pages/job/partials/One-Off-Schedule-Header.vue";
import OneOffScheduleBody from "@/view/pages/job/partials/One-Off-Schedule-Body.vue";
import JobScheduleTeam from "@/view/pages/job/partials/Job-Schedule-Team.vue";

export default {
  name: "one-off-schedule",
  props: {
    showCalendar: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    dailyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    monthlyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
    weeklyRecurrence: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  components: {
    OneOffScheduleHeader,
    OneOffScheduleBody,
    JobScheduleTeam,
  },
};
</script>
