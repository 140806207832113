<template>
  <div class="one-off-schedule-header d-flex">
    <div class="width-100 margin-auto">
      <h3 class="m-0 text-uppercase font-weight-700 color-custom-blue">
        Schedule
      </h3>
    </div>
    <div v-if="!internal">
      <v-btn
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        right
        small
        v-on:click="updateShowCalendar"
      >
        <v-icon small class="mr-2">mdi-calendar-blank</v-icon
        ><span v-if="showCalendar">Hide Calendar</span
        ><span v-else>Show Calendar</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { JobEventBus } from "@/core/lib/job/job.lib";

export default {
  name: "one-off-schedule-header",
  props: {
    showCalendar: {
      type: Boolean,
      required: true,
    },
    internal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    updateShowCalendar() {
      JobEventBus.$emit("update:show-calendar");
    },
  },
};
</script>
